import Home from '../views/pages/Home.vue'
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import Account from '../views/account/Account.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import SetPassword from '../views/auth/SetPassword.vue'
import Monitoring from '../views/monitoring/Monitoring.vue'
import Contact from '@/views/pages/Contact'
import About from '@/views/pages/About'
import Pricing from '@/views/pages/Pricing'
import Faq from '@/views/pages/Faq'
import Index from '../views/Index.vue'
import middleware from './middleware'

export default [
	{
		path: '/login',
		name: 'login',
		component: Login,
		beforeEnter: middleware.guest,
	},
	{
		path: '/register',
		name: 'register',
		component: Register,
		beforeEnter: middleware.guest,
	},
	{
		path: '/forgot-password',
		name: 'forgotPassword',
		component: ForgotPassword,
		beforeEnter: middleware.guest,
	},
	{
		path: '/reset-password',
		name: 'resetPassword',
		component: ResetPassword,
		beforeEnter: middleware.guest,
	},
	{
		path: '/set-password',
		name: 'setPassword',
		component: SetPassword,
		beforeEnter: middleware.guest,
	},

	{
		path: '/',
		component: Index,
		props: { permission: '' },
		children: [
			{
				path: '',
				component: Home,
				name: 'Home',
			},
			{
				path: '/contact',
				component: Contact,
			},
			{
				path: '/about',
				component: About,
			},
			{
				path: '/pricing',
				component: Pricing,
			},
			{
				path: '/faq',
				component: Faq,
			},

			{
				path: '/account',
				component: Account,
				props: { permission: '' },
				meta: {
					title: 'Your account',
					backTo: '/',
				},
				beforeEnter: middleware.user,
				children: [
					{
						path: '',
						component: () => import(/* webpackChunkName: "account" */ '../views/account/AccountInfo.vue'),
					},
					{
						path: 'edit',
						name: 'AccountEdit',
						component: () => import(/* webpackChunkName: "account" */ '../views/account/AccountEdit.vue'),
						beforeEnter: middleware.user,
					},
					{
						path: 'change-password',
						name: 'AccountChangePassword',
						component: () =>
							import(/* webpackChunkName: "account" */ '../views/account/AccountChangePassword.vue'),
						beforeEnter: middleware.user,
					},
					{
						path: 'notifications',
						name: 'AccountNotifications',
						component: () =>
							import(/* webpackChunkName: "account" */ '../views/account/AccountNotifications.vue'),
						beforeEnter: middleware.user,
					},
				],
			},
			{
				path: '/monitoring',
				component: Monitoring,
				props: { permission: '' },
				meta: {
					title: 'Your monitoring',
					backTo: '/',
				},
				beforeEnter: middleware.user,
				children: [
					{
						path: '',
						name: 'Monitoring',
						component: () =>
							import(/* webpackChunkName: "monitoring" */ '../views/monitoring/MonitoringList.vue'),
					},
					{
						path: 'create',
						name: 'MonitoringCreate',
						component: () =>
							import(/* webpackChunkName: "monitoring" */ '../views/monitoring/MonitoringCreate.vue'),
						beforeEnter: middleware.user,
					},
					{
						path: 'edit/:id',
						name: 'MonitoringEdit',
						component: () =>
							import(/* webpackChunkName: "monitoring" */ '../views/monitoring/MonitoringEdit.vue'),
						beforeEnter: middleware.user,
					},
					{
						path: 'view/:id',
						name: 'MonitoringView',
						component: () =>
							import(/* webpackChunkName: "monitoring" */ '../views/monitoring/MonitoringView.vue'),
						beforeEnter: middleware.user,
					},
				],
			},
		],
	},
]
