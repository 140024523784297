<template>
	<!-- FAQ -->
	<div class="">
		<!-- Page Header -->
		<div class="page-header d-flex justify-content-center align-items-center mb-5 py-4 text-center">
			<img src="../../../public/images/faq-header.svg" width="96" class="me-3" alt="" />
			<div class="">
				<h1 class="">
					FAQs
				</h1>
				<p class="text-gray">Frequently ask questions</p>
			</div>
		</div>

		<div class="container">
			<div class="row">
				<div class="col-md-12 text-center">
					FAQS
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	name: 'Faq',
	data() {
		return {}
	},
	computed: {
		...mapState(['authUser']),
		...mapGetters(['isAuth']),
	},
	created() {},
	methods: {
		// Get campaigns data
	},
}
</script>
