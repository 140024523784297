<template>
	<div class="w-100">
		<Header />
		<div class="container v-center">
			<div class="row d-flex justify-content-center align-items-center login-page">
				<div class="col-md-7">
					<img src="../../../public/images/create-account.svg" class="w-100 rounded" alt="" />
				</div>
				<div class="col-md-5">
					<div class="card p-4 rounded shadow">
						<h3 class="">Create account</h3>
						<p>
							or
							<router-link class="text-decoration-none" to="/login">
								sign in
							</router-link>
						</p>
						<div v-if="info" class="alert alert-info" role="alert">
							{{ info }}
						</div>
						<form v-if="!info" @submit.prevent="register">
							<div class="form-group mb-2">
								<label for="name" class="form-label">Name</label>
								<input type="text" class="form-control" placeholder="Your name" v-model="user.name" />
							</div>

							<div class="form-group mb-2">
								<label for="email" class="form-label">Email</label>
								<input
									type="email"
									class="form-control"
									placeholder="Your email"
									v-model="user.email"
									required
								/>
							</div>

							<div class="form-group mb-2">
								<label for="password" class="form-label">Password</label>
								<input
									type="password"
									class="form-control"
									placeholder="Your password"
									v-model="user.password"
									minlength="6"
									required
								/>
							</div>

							<div class="form-group mb-3">
								<label for="passwordConfirmation" class="form-label">Confirm Password</label>
								<input
									type="password"
									class="form-control"
									placeholder="Confirm your password"
									v-model="user.password_confirmation"
									minlength="6"
									required
								/>
							</div>

							<button class="btn btn-primary w-100" :disabled="loading">
								<font-awesome-icon 
									v-if="loading"
									:icon="['fas', 'spinner']" 
									class="me-1 fa-spin" 
								/> 
								Register
							</button>
						</form>
						<div v-else>
							<h4 class="text-center">🎉 Your account is successfully created, you can now login</h4>
							<router-link class="btn btn-primary w-100 text-white" to="/login">
								Login
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
import restApi from '../../api'
import { handleErrors } from '../../actions/helpers.js'
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
export default {
	name: 'Register',
	components: { Header, Footer },
	data() {
		return {
			user: {
				name: '',
				email: '',
				password: '',
				password_confirmation: '',
			},
			loading: false,
			loadingError: '',
			info: '',
		}
	},
	methods: {
		// Function to register user on system
		async register() {
			this.loading = true
			restApi
				.post(`/register`, this.user)
				.then(({ data }) => {
					this.info = data.message
					this.loading = false
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						text: handleErrors(error, 'register'),
					})
					this.loading = false
				})
		},
	},
}
</script>
<style>
.v-center{
	height: calc(100vh - 104px);
	justify-content: center;
	display: flex;
}
</style>
