<template>
	<div class="w-100">
		<Header />
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'

export default {
	name: 'Index',
	components: { Header, Footer },
	mounted: function() {
		// Load account data after page reload
		this.$nextTick(function() {
			this.$store.dispatch('accountInfo')
		})
	},
}
</script>
