<template>
	<!-- Homepage -->
	<div class="container text-center mt-5 vh-100">
		<div class="row">
			<div class="col-md-12">
				<h1>Welcome to Site Monitoring APP</h1>
				<p>
					Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit,
					eget tincidunt nibh pulvinar a. Curabitur aliquet quam id dui posuere blandit. Curabitur non nulla
					sit amet nisl tempus convallis quis ac lectus. Curabitur aliquet quam id dui posuere blandit.
					Quisque velit nisi, pretium ut lacinia in, elementum id enim. Lorem ipsum dolor sit amet,
					consectetur adipiscing elit. Donec sollicitudin molestie malesuada. Vivamus suscipit tortor eget
					felis porttitor volutpat. Cras ultricies ligula sed magna dictum porta. Donec rutrum congue leo eget
					malesuada. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Nulla porttitor accumsan
					tincidunt. Pellentesque in ipsum id orci porta dapibus. Nulla quis lorem ut libero malesuada
					feugiat.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	name: 'Home',
	data() {
		return {}
	},
	computed: {
		...mapState(['authUser']),
		...mapGetters(['isAuth']),
	},
	created() {},
	methods: {
		// Get campaigns data
	},
}
</script>
