<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap');
@import '../src/assets/sass/design';

#app {
	display: flex;
	/*background-color: #f8f8f8;*/
	background-color: #f0f8fe;
	nav {
		padding: 5px 10px;
	}
}

.col-content {
	min-height: calc(100vh - 132px);
}

@media (max-width: 575.98px) {
	.col-sidebar {
		width: 0 !important;
		max-width: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
	}
}
</style>
