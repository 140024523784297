<template>
	<!-- About -->
	<div class="">
		<!-- Page Header -->
		<div class="page-header d-flex justify-content-center align-items-center mb-5 py-4 text-center">
			<img src="../../../public/images/about-us-header.svg" width="64" class="me-3" alt="" />
			<div class="">
				<h1 class="">
					About Us
				</h1>
				<p class="text-gray">Find things about us</p>
			</div>
		</div>
		<div class="container vh-100">
			<div class="row">
				<div class="col-md-12">
					About us
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	name: 'About',
	data() {
		return {}
	},
	computed: {
		...mapState(['authUser']),
		...mapGetters(['isAuth']),
	},
	created() {},
	methods: {
		// Get campaigns data
	},
}
</script>
