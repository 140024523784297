import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	routes, // short for `routes: routes`
})

/* router.onError(error => {
	console.error(error)
	Vue.prototype.$log.error('Failure Reason: ', error.message, error)
	if (/ChunkLoadError:.*failed./i.test(error.message)) {
		Vue.prototype.$log.error('Reloading Window 1')
		window.location.reload()
	} else if (/Loading.*chunk.*failed./i.test(error.message)) {
		Vue.prototype.$log.error('Reloading Window 2')
		window.location.reload()
	}
}) */

export default router
