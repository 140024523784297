<template>
	<div class="w-100">
		<Header />
		<div class="container v-center">
			<div class="row d-flex justify-content-center align-items-center login-page">
				<div class="col-md-7">
					<img src="../../../public/images/forgot-password.svg" class="w-100 rounded" alt="" />
				</div>
				<div class="col-md-5">
					<div class="card p-4 rounded shadow">
						<div class="d-flex justify-content-between align-items-center">
							<h3 class="">Forgot password?</h3>
							<router-link class="text-decoration-none" to="/login">
								Sign in
							</router-link>
						</div>
						<p>We will send you an email with instructions for changing your password.</p>
						<div v-if="info" class="alert alert-info" role="alert">
							{{ info }}
						</div>
						<form @submit.prevent="sendResetPasswordLink">
							<label for="email" class="form-label">Email</label>
							<input
								type="email"
								class="form-control"
								placeholder="Your email"
								v-model="email"
								required
							/><br />
							<button class="btn btn-primary w-100" :disabled="loading">
								<font-awesome-icon 
									v-if="loading"
									:icon="['fas', 'spinner']" 
									class="me-1 fa-spin" 
								/> 
								Request Password Reset
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
import restApi from '../../api'
import { handleErrors } from '../../actions/helpers'
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'

export default {
	name: 'ForgotPassword',
	components: { Header, Footer },
	data() {
		return {
			info: '',
			email: '',
			loading: false,
		}
	},
	methods: {
		async sendResetPasswordLink() {
			this.loading = true
			restApi.post(`/forgot-password`, { email: this.email }).then(
				({ data }) => {
					this.info = data.message
					this.loading = false
				},
				error => {
					this.info = ''
					this.$swal.fire({
						icon: 'error',
						title: 'Oops..',
						text: handleErrors(error, 'forgotPassword'),
					})
					this.loading = false
				}
			)
		},
	},
}
</script>
