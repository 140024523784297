<template>
	<!-- Homepage -->
	<div class="">
		<!-- Page Header -->
		<div class="page-header d-flex justify-content-center align-items-center mb-5 py-4 text-center">
			<img src="../../../public/images/contact-header.svg" width="96" class="me-3" alt="" />
			<div class="">
				<h1 class="">
					Contact Us
				</h1>
				<p class="text-gray">Feel free to contact us for any question</p>
			</div>
		</div>

		<div class="container">
			<div class="row py-5">
				<div class="col-md-5">
					<img src="../../../public/images/contact-img.svg" class="w-100" alt="" />
				</div>
				<div class="col-md-7">
					<div class="card p-3 m-3 mx-5">
						<div class="card-body">
							<form action="">
								<div class="form-group">
									<label for="name">Name</label>
									<input type="text" class="form-control" name="name" />
								</div>
								<div class="form-group">
									<label for="email">Email</label>
									<input type="email" class="form-control" name="email" />
								</div>
								<div class="form-group">
									<label for="message">Message</label>
									<textarea type="email" class="form-control" rows="8" name="message" />
								</div>
								<button class="btn btn-primary mt-2 float-end">Send Message</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	name: 'Contact',
	data() {
		return {}
	},
	computed: {
		...mapState(['authUser']),
		...mapGetters(['isAuth']),
	},
	created() {},
	methods: {
		// Get campaigns data
	},
}
</script>
