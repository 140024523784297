<template>
	<nav class="navbar navbar-expand-lg shadow-sm bg-white">
		<router-link class="navbar-brand" to="/"><h4 class="m-0 p-0 text-black">Site Monitoring</h4></router-link>
		<button
			class="navbar-toggler"
			type="button"
			data-bs-toggle="collapse"
			data-bs-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav me-auto mb-2 mb-lg-0">
				<li class="nav-item">
					<router-link class="nav-link" to="/"> Home </router-link>
				</li>
				<li v-if="isAuth" class="nav-item ">
					<router-link class="nav-link bg-light rounded-1" to="/monitoring">🖥️ Monitoring </router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link" to="/about"> About </router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link" to="/pricing"> Pricing </router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link" to="/faq"> Faq </router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link" to="/contact"> Contact </router-link>
				</li>
			</ul>
			<div class="d-flex">
				<div class="dropdown" v-if="isAuth">
					<button
						class="btn dropdown-toggle shadow-none d-flex align-items-center"
						type="button"
						id="dropdownMenuButton"
						data-bs-toggle="dropdown"
						aria-bs-expanded="false"
					>
						<Avatar :width="24" :height="24" class="shadow" :user="account" />
						<span v-if="account" class="ms-1">{{ account.name }}</span>
					</button>
					<ul class="dropdown-menu" aria-labelledby="account">
						<li>
							<router-link class="dropdown-item" to="/account">🙂 Account </router-link>
						</li>
						<li>
							<router-link class="dropdown-item" to="/monitoring">🖥️ Monitoring </router-link>
						</li>
						<li><hr class=" m-1" /></li>
						<li><a role="button" class="dropdown-item" @click="logout()">🚪 Logout</a></li>
					</ul>
				</div>
				<div v-else>
					<router-link class="btn btn-link text-decoration-none" to="/login"> Sign in </router-link>
					<router-link class="btn btn-primary" to="/register"> Create account </router-link>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import restApi from '../../api/index'
import { mapState, mapGetters } from 'vuex'
import Avatar from '../user/Avatar.vue'
import { handleErrors } from '../../actions/helpers'
export default {
	name: 'Header',
	components: {
		Avatar,
	},
	data() {
		return {
			loading: false,
			monitorings: [],
		}
	},
	computed: {
		...mapState(['token', 'authUser', 'account']),
		...mapGetters(['isAuth']),
	},
	methods: {
		async logout() {
			this.loading = true
			try {
				await this.$store.dispatch('authLogout', this.token)
			} catch (error) {
				this.$swal.fire({
					icon: 'error',
					title: handleErrors(error, ''),
					timer: 6000,
				})
			} finally {
				this.loading = false
				if (this.$route.name !== 'Home') {
					this.$router.push({ name: 'Home' })
				}
			}
		},

		// Get monitorings
		getMonitorings() {
			restApi
				.get('/monitorings')
				.then(({ data }) => {
					this.monitorings = data
				})
				.catch(error => {
					console.log(error)
				})
		},
	},
}
</script>
