<template>
	<div class="w-100">
		<Header />
		<div class="container v-center">
			<div class="row d-flex justify-content-center align-items-center login-page">
				<div class="col-md-7">
					<img src="../../../public/images/confirm-password.svg" class="w-100 rounded" alt="" />
				</div>
				<div class="col-md-5">
					<div class="card p-4 rounded shadow">
						<div class="d-flex justify-content-between align-items-center">
							<h3 class="">Reset password</h3>
							<router-link class="text-decoration-none" to="/login">
								Sign in
							</router-link>
						</div>
						<p>🔐 Reset your password for Site Monitoring App</p>
						<!-- If there is no reset confirmation show form to set new password -->
						<form v-if="!info" @submit.prevent="sendResetPasswordLink">
							<div class="form-group mb-2">
								<label for="password" class="form-label">Password</label>
								<input type="password" class="form-control" v-model="password" required />
							</div>

							<div class="form-group mb-2">
								<label for="confirmPassword" class="form-label">Confirm Password</label>
								<input type="password" class="form-control" v-model="confirmPassword" required />
							</div>
							<br />
							<button class="btn btn-primary w-100" :disabled="loading">
								<font-awesome-icon 
									v-if="loading"
									:icon="['fas', 'spinner']" 
									class="me-1 fa-spin" 
								/> 
								Sign in
							</button>
						</form>
						<!-- Confirmation of reset password -->
						<div v-else>
							<div class="alert alert-info" role="alert">
								Your password has been successfully reset. <br />
								You can now sign in with your new password.
							</div>
							<router-link class="btn btn-primary w-100" to="/login">
								Sign in
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
import restApi from '../../api'
import { handleErrors } from '../../actions/helpers'
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
export default {
	name: 'ResetPassword',
	components: { Header, Footer },
	data() {
		return {
			info: '',
			email: '',
			password: '',
			confirmPassword: '',
			passwordToken: '',
			loading: false,
		}
	},
	created() {
		this.passwordToken = this.$route.query.token
		this.email = this.$route.query.email
	},
	methods: {
		async sendResetPasswordLink() {
			this.loading = true
			restApi
				.post(`/reset-password`, {
					email: this.email,
					password: this.password,
					password_confirmation: this.confirmPassword,
					passwordToken: this.passwordToken,
				})
				.then(
					({ data }) => {
						this.info = data.message
						this.loading = false
					},
					error => {
						this.info = ''
						this.$swal.fire({
							icon: 'error',
							title: 'Oops..',
							text: handleErrors(error, 'resetPassword'),
						})
						this.loading = false
					}
				)
		},
	},
}
</script>
