<template>
	<div class="w-100">
		<Header />
		<div class="container v-center">
			<div class="row d-flex justify-content-center align-items-center login-page">
				<div class="col-md-7">
					<img src="../../../public/images/sign-in.svg" class="w-100 rounded" alt="" />
				</div>
				<div class="col-md-5">
					<div class="card p-4 rounded shadow">
						<h3 class="">Sign in</h3>
						<p>
							or
							<router-link class="text-decoration-none" to="/register">
								create an account
							</router-link>
						</p>
						<form @submit.prevent="login">
							<label for="email" class="form-label">Email</label>
							<input
								type="email"
								class="form-control"
								placeholder="Your email"
								v-model="user.email"
								required
							/><br />
							<label for="password" class="form-label">Password</label>
							<input
								type="password"
								class="form-control"
								placeholder="Your password"
								v-model="user.password"
								minlength="3"
								required
							/><br />
							<button class="btn btn-primary w-100" :disabled="loading">
								<font-awesome-icon 
									v-if="loading"
									:icon="['fas', 'spinner']" 
									class="me-1 fa-spin" 
								/> 
								Sign in
							</button>
							
						</form>
					</div>
					<div class="text-center pt-2">
						<router-link class="text-decoration-none text-primary" to="/forgot-password">
							Forgot password?
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
import { handleErrors } from '../../actions/helpers'
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
export default {
	name: 'Login',
	components: { Header, Footer},
	data() {
		return {
			user: {
				email: null,
				password: null,
			},
			loading: false,
		}
	},
	mounted() {
		window.addEventListener('message', this.onMessage, false)
	},
	beforeDestroy() {
		window.removeEventListener('message', this.onMessage)
	},
	methods: {
		/**
		 * @param {MessageEvent} e
		 */
		async onMessage(e) {
			if (
				e.data.source !== 'vue-devtools-proxy' &&
				e.data.source !== 'vue-devtools-backend' &&
				e.data.source !== 'vue-devtools-backend-injection'
			) {
				//console.log(e.origin)
			}

			// TODO: Need better way for this catching message for social login, better url from config
			if (e.origin === 'http://127.0.0.1:8000') {
				this.$store.commit('SET_AUTH', e.data.token)
				window.removeEventListener('message', this.onMessage)
				this.$router.push('/')
			}
		},

		// Function to authenticate user with email and password
		async login() {
			this.loading = true
			await this.$store
				.dispatch('authLogin', this.user)
				.then(({ data }) => {
					if (data.success) {
						this.$router.push('/')
					}
					this.loading = false
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops..',
						text: handleErrors(error, 'login'),
					})
					this.loading = false
				})
		},

		// Authenticate with Github Account
		async githubLogin() {
			const newWindow = openWindow('', 'Login')

			try {
				const url = await this.$store.dispatch('authLoginSocialite', 'github', this.user)
				newWindow.location.href = url
			} catch (error) {
				this.$swal.fire({
					icon: 'error',
					title: 'Oops..',
					text: handleErrors(error, 'login'),
				})
			} finally {
				this.loading = false
			}
		},

		// Authenticate with Google Account
		async googleLogin() {
			const newWindow = openWindow('', 'Login')

			try {
				const url = await this.$store.dispatch('authLoginSocialite', 'google', this.user)
				newWindow.location.href = url
			} catch (error) {
				this.$swal.fire({
					icon: 'error',
					title: 'Oops..',
					text: handleErrors(error, 'login'),
				})
			} finally {
				this.loading = false
			}
		},

		async facebookLogin() {
			const newWindow = openWindow('', 'Login')

			try {
				const url = await this.$store.dispatch('authLoginSocialite', 'facebook', this.user)
				newWindow.location.href = url
			} catch (error) {
				this.$swal.fire({
					icon: 'error',
					title: 'Oops..',
					text: handleErrors(error, 'login'),
				})
			} finally {
				this.loading = false
			}
		},
	},
}

/**
 * @param  {Object} options
 * @return {Window}
 */
function openWindow(url, title, options = {}) {
	if (typeof url === 'object') {
		options = url
		url = ''
	}
	options = { url, title, width: 600, height: 720, ...options }
	const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
	const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
	const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
	const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height
	options.left = width / 2 - options.width / 2 + dualScreenLeft
	options.top = height / 2 - options.height / 2 + dualScreenTop
	const optionsStr = Object.keys(options)
		.reduce((acc, key) => {
			acc.push(`${key}=${options[key]}`)
			return acc
		}, [])
		.join(',')
	const newWindow = window.open(url, title, optionsStr)
	if (window.focus) {
		newWindow.focus()
	}
	return newWindow
}
</script>
