<template>
	<footer class="text-center">
		<!-- Copyright -->
		<div class="text-center p-3">
			© 2022 Copyright:
			<a class="text-primary text-decoration-none" target="_blank" href="https://likaprogramming.com/"
				>LikaProgramming</a
			>
		</div>
		<!-- Copyright -->
	</footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	name: 'Footer',
	components: {},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		...mapState(['token', 'authUser', 'account']),
		...mapGetters(['isAuth']),
	},
	methods: {},
}
</script>
