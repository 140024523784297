import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'

import 'bootstrap'

Vue.use(VueSweetalert2, {
	//toast: true,
	//position: 'top-end',
	showConfirmButton: true,
	//timer: 5000,
	//timerProgressBar: true,
	confirmButtonColor: '#2196f3'
})

import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faAddressBook,
	faArrowCircleDown,
	faArrowCircleUp,
	faBars,
	faBox,
	faCheckCircle,
	faCity,
	faClock,
	faCogs,
	faEdit,
	faEnvelope,
	faFileCsv,
	faHistory,
	faHouseUser,
	faInfoCircle,
	faLock,
	faMailBulk,
	faPaperclip,
	faPlus,
	faPlusCircle,
	faTimes,
	faUser,
	faUserEdit,
	faUserPlus,
	faUsers,
	faUserTie,
	faTrash,
	faEye,
	faPenSquare,
	faShippingFast,
	faFilePdf,
	faGlobe,
	faShareSquare,
	faBell,
	faMoneyBill,
	faCamera,
	faList,
	faThLarge,
	faArrowLeft,
	faSync,
	faSpinner
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebook, faGithub, faGoogle, faWikipediaW } from '@fortawesome/free-brands-svg-icons'

library.add(
	faBars,
	faHouseUser,
	faPaperclip,
	faBox,
	faCogs,
	faUsers,
	faGithub,
	faGoogle,
	faFacebook,
	faInfoCircle,
	faUser,
	faClock,
	faLock,
	faUserEdit,
	faEdit,
	faUserPlus,
	faHistory,
	faArrowCircleDown,
	faArrowCircleUp,
	faCity,
	faFileCsv,
	faCheckCircle,
	faPlus,
	faPlusCircle,
	faTimes,
	faMailBulk,
	faAddressBook,
	faUserTie,
	faEnvelope,
	faTrash,
	faEye,
	faPenSquare,
	faShippingFast,
	faFilePdf,
	faWikipediaW,
	faGlobe,
	faShareSquare,
	faBell,
	faMoneyBill,
	faCamera,
	faList,
	faThLarge,
	faArrowLeft,
	faSync,
	faEye,
	faSpinner
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
