import store from '../../../store'

export default (to, from, next) => {
	if (store.getters['authenticated']) {
		console.log('here')
		next({ name: 'Home' })
	} else {
		next()
	}
}
