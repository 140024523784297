<template>
	<div>
		<div class="container lp-full-height">
			<div class="row justify-content-center">
				<div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		currentRouteName() {
			return this.$route.name
		},
	},
}
</script>
